import React, { useCallback } from "react";
import { parseISO, setHours } from "date-fns";

import { useSearchState } from "../search-context";
import { useSearchDispatch } from "../search-context";
import Form from "./Form";

const Search = ({ params }) => {
  const searchState = useSearchState();
  const setSearch = useSearchDispatch();

  if (params !== undefined) {
    // Convert fromDate and toDate to dates if passed as strings
    const fromDate =
      typeof params.fromDate === "string"
        ? parseISO(params.fromDate)
        : params.fromDate;

    params.minToTimes = setHours(fromDate, 8);
    params.maxFromTimes = setHours(fromDate, 18);
    const toDate =
      typeof params.toDate === "string"
        ? parseISO(params.toDate)
        : params.toDate;

    if (params.fromAlgolia.administrative === "Île-de-France") {
      params.proximite_km = "0.06";
    }

    params = { ...params, fromDate, toDate };
  }

  React.useEffect(() => {
    if (searchState.status === "prune" && params !== undefined) {
      // Updtate state context to launch data fetching
      setSearch({
        type: "submit",
        payload: params,
      });
    }
  }, [searchState.status, params, setSearch]);

  const handleSubmit = useCallback(
    (state) => {
      // Default range is 10km except for Ile de France which is 6km
      state.proximite_km = "0.1";
      if (state.fromAlgolia.administrative === "Île-de-France") {
        state.proximite_km = "0.06";
      }
      setSearch({
        type: "submit",
        payload: state,
      });
    },
    [setSearch]
  );

  return <Form params={params} callback={handleSubmit} />;
};

export default Search;
