import React from "react";
import { Progress } from "antd";
import styled from "styled-components";
import slugify from "slugify";

import Result from "./Result";
import HiflowDriiveme from "./HiflowDriiveme";
import { useOffersState, useOffersDispatch } from "../offers-context";
import { useSearchState } from "../search-context";

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const LoadingWrapper = styled.div`
  text-align: center;
  padding: 2rem 1rem;
`;
const Label = styled.div`
  text-align: center;
  padding-top: 1rem;
`;
const Noresults = styled.div`
  text-align: center;
  padding: 2rem 1rem;
  font-size: 1rem;
`;
const StatsWrapper = styled.div`
  padding: 0.5rem 0;
`;
const ResetButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${primaryColor};
  cursor: pointer;
`;

const Loading = () => {
  const offers = useOffersState();

  if (offers.status !== "data-loading") return null;

  return (
    <LoadingWrapper className="dms-loader">
      <Progress type="circle" percent={offers.progress} />
      <Label>
        Recherche en cours, nous interrogeons en temps réel les prix et
        disponibilités des loueurs.
      </Label>
    </LoadingWrapper>
  );
};

const Stats = () => {
  const offers = useOffersState();
  const setOffers = useOffersDispatch();

  const resetFilters = React.useCallback(() => {
    setOffers({ type: "reset-filters" });
  }, [setOffers]);

  if (
    offers.status === "loaded" &&
    offers.filtered.length !== offers.offers.length
  )
    return (
      <StatsWrapper className="dms-stats">
        Résultats : {offers.filtered.length} sur {offers.offers.length}{" "}
        <ResetButton onClick={resetFilters} role="button">
          (afficher tout)
        </ResetButton>
      </StatsWrapper>
    );

  return <StatsWrapper />;
};

const Results = ({ callback }) => {
  const offers = useOffersState();
  const params = useSearchState();

  const onMouseEnterResult = React.useCallback(
    (key, volume) => {
      callback(key, volume);
    },
    [callback]
  );

  const annonces = [];

  /** one way */
  if (
    slugify(params.vca_depart, { lower: true }) !==
    slugify(params.vca_retour, { lower: true })
  ) {
    annonces.push(<HiflowDriiveme />);
  }
  if (offers.status === "loaded" && offers.offers.length === 0)
    return (
      <>
        <Noresults>
          Aucun résultat trouvé pour les dates demandées, veuillez lancer une
          nouvelle recherche sur d'autres dates ou une ville à proximité !
        </Noresults>
        <div>{annonces}</div>
      </>
    );

  const items = [];

  offers.filtered.forEach((item) => {
    items.push(
      <Result
        offer={item}
        pid={params.partenaireId}
        key={item.id}
        callback={onMouseEnterResult}
      />
    );
  });

  return (
    <div className="dms-results">
      <Loading />
      <Stats />
      <div>
        {annonces}
        {items}
      </div>
    </div>
  );
};

export default Results;
