const trackOutboundLink = (outBound, url, pid) => {
  const regexFrancecars = new RegExp("france[-s]?cars", "i");
  const regexLocationU = new RegExp("location[-s]?u", "i");
  const arr_report_conversion = {
    Enterprise: "AW-754429481/iXgbCObkmMkBEKnc3ucC",
    Sixt: "AW-754429481/9A4UCJPimMkBEKnc3ucC",
    Rentacar: "AW-754429481/NJfuCKLimMkBEKnc3ucC",
    Hertz: "AW-754429481/tSPzCMDimMkBEKnc3ucC",
    Flycar: "AW-754429481/NAsRCLLmmMkBEKnc3ucC",
    Ouicar: "AW-754429481/hYV6CKXnqMkBEKnc3ucC",
    Ada: "AW-754429481/-WIVCPjsqMkBEKnc3ucC",
    Francecars: "AW-754429481/PdKHCIPvqMkBEKnc3ucC",
    Avis: "AW-754429481/Ip9ACPbwqMkBEKnc3ucC",
    Cargo: "AW-754429481/kHfXCIvxqMkBEKnc3ucC",
    Leclerc: "AW-754429481/RLndCKbxqMkBEKnc3ucC",
    Driiveme: "AW-754429481/U0AECNHyqMkBEKnc3ucC",
    LocationU: "AW-754429481/lCZhCKn0qMkBEKnc3ucC",
    Europcar: "AW-754429481/iY-0CL6ZsskBEKnc3ucC",
    Hiflow: "AW-754429481/KgtHCJywsskBEKnc3ucC",
    Luckyloc: "AW-754429481/KgtHCJywsskBEKnc3ucC",
    Getaround: "AW-754429481/bnSnCKuwsskBEKnc3ucC",
  };

  const arr_pid = {
    2: "mydemenageur-mb",
    5: "cargo-mb",
  };

  if (regexFrancecars.test(outBound, "i")) {
    outBound = "Francecars";
  } else if (regexLocationU.test(outBound, "i")) {
    outBound = "LocationU";
  } else {
    outBound = `${outBound
      .charAt(0)
      .toUpperCase()}${outBound.toLowerCase().slice(1)}`;
  }

  let nom = outBound;
  if (pid !== undefined) {
    nom = `${outBound}_PR`;
    if (arr_pid.hasOwnProperty(pid)) {
      nom = `${arr_pid[pid]}-${outBound}`;
    }
  }

  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "Outbound_" + nom,
      event_label: url,
      transport_type: "beacon",
    });

  typeof window !== "undefined" &&
    window.gtag("event", "Outbound_Adwords_" + nom, {
      send_to: "AW-1033617891/9UqlCOeJ1IIBEOOD7-wD",
      transaction_id: "",
    });

  if (arr_report_conversion.hasOwnProperty(outBound)) {
    typeof window !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: arr_report_conversion[outBound],
      });
  }
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export { trackOutboundLink, getDeviceType };
