import React from "react";
import styled from "styled-components";

import iconbplaces from "../img/ico-nb-places.svg";
import icoboitemanuelle from "../img/ico-boite-manuelle.svg";
import icoclim from "../img/ico-clim.svg";
import iconbportes from "../img/ico-nb-portes.svg";
import icoboiteauto from "../img/ico-boite-auto.svg";

const Wrapper = styled.div`
  flex: 1;
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
`;
const Places = styled.div`
  width: 65px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background-color: #f0f0f0;
  color: #414141;
  margin: 0 4px;
  flex: none;
  padding: 0 12px;
  align-items: center;
`;
const Portes = styled.div`
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin: 0 4px;
  flex: none;
  align-items: center;
  color: #912332;
`;
const Icon = styled.span`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 8px;
  background-color: #515151;
  color: #ffffff;
  margin: 0 4px;
  flex: none;
  justify-content: center;
  align-items: center;
`;

const Commodities = ({ data }) => {
  const items = [];

  if (data.hasOwnProperty("nb_places") && data.nb_places) {
    items.push(
      <Places key="places">
        <img
          src={iconbplaces}
          alt="Nombre de places"
          style={{ height: "18px" }}
        />
        {data.nb_places}
      </Places>
    );
  }

  if (data.hasOwnProperty("climatisation") && data.climatisation) {
    items.push(
      <Icon key="clim">
        <img src={icoclim} alt="Climatisé" style={{ height: "18px" }} />
      </Icon>
    );
  }

  if (data.hasOwnProperty("type_boite") && data.type_boite) {
    if (data.type_boite.toUpperCase() === "MANUEL")
      items.push(
        <Icon key="boite-manuelle">
          <img
            src={icoboitemanuelle}
            alt="Boîte manuelle"
            style={{ height: "18px" }}
          />
        </Icon>
      );
    if (data.type_boite.toUpperCase() === "AUTOMATIQUE")
      items.push(
        <Icon key="boite-auto">
          <img
            src={icoboiteauto}
            alt="Boîte automatique"
            style={{ height: "18px" }}
          />
        </Icon>
      );
  }

  if (data.hasOwnProperty("nb_portes") && data.nb_portes) {
    items.push(
      <Portes key="portes">
        <Icon>
          <img
            src={iconbportes}
            alt="Nombre de portes"
            style={{ height: "14px" }}
          />
        </Icon>
        {data.nb_portes}
      </Portes>
    );
  }

  return <Wrapper>{items}</Wrapper>;
};

export default Commodities;
