import React from "react";
import styled from "styled-components";

import Action from "./Action";

const Wrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #d3d3d3;
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    flex-direction: column;
  }
`;
const InnerLeft = styled.div`
  flex: 1;
`;
const InnerRight = styled.div`
  width: 290px;
  flex: none;
  display: flex;
  flex-direction: row;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    width: 100%;
  }
`;
const PhotoWrapper = styled.div`
  width: 140px;
  height: 100%;
  overflow: hidden;
  flex: none;
  display: flex;
  align-items: center;
`;
const PriceCont = styled.div`
  display: flex;
  width: 140px;
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    width: auto;
    flex: 1;
  }
`;
const InfosAnnonce = styled.div`
  position: absolute;
  text-transform: uppercase;
  font-size: 0.7rem;
  top: -8px;
  left: -8px;
  color: #555;
  padding: 0 10px;
  background: #eee;
  border-radius: 6px 0 6px 0;
`;
const InfosDetails = styled.p`
  margin: 0.5rem 0;
  font-size: 0.8rem;
  color: gray;
`;
const InfosPlus = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
`;
const InfosLogo = styled.div`
  width: 200px;
  height: 30px;
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.img`
  width: 100%;
  max-width: 80px;
  max-height: 30px;
`;
const LogoSmall = styled.img`
  width: 60px;
  height: 22px;
  margin-right: 10px;
`;
const RowPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`;
const Photo = styled.img`
  width: 100%;
  max-height: 120px;
`;

const offerh = { agence: "HIFLOW" };
const offerd = { agence: "DRIIVEME" };

const Hiflow = () => {
  return (
    <Wrapper className="dms-result">
      <Inner className="dms-result-inner">
        <InnerLeft className="dms-result-inner-left">
          <InfosAnnonce>Annonce</InfosAnnonce>
          <InfosDetails>
            Louez un utilitaire en aller simple de Paris à Nice pour 1€! Créez
            une alerte!
          </InfosDetails>
          <InfosPlus className="dms-infoplus">
            <InfosLogo>
              <Logo
                src="https://media.digitalconsultingfze.com/img/logosml/luckyloc.gif"
                alt="hiflow"
              />
              <Logo
                src="https://media.digitalconsultingfze.com/img/logosml/driiveme.gif"
                alt="driiveme"
              />
            </InfosLogo>
          </InfosPlus>
        </InnerLeft>
        <InnerRight className="dms-result-inner-right">
          <PhotoWrapper>
            <Photo
              alt="vehicle-category"
              src="https://media.digitalconsultingfze.com/img/veh-driiveme/vehicle-category-6.png"
            />
          </PhotoWrapper>
          <PriceCont>
            A partir de 1€*
            <RowPrice>
              <LogoSmall
                src="https://media.digitalconsultingfze.com/img/logosml/luckyloc.gif"
                alt="hiflow"
              />
              <Action
                url="https://www.hiflow.com/location/resultats?particular=0&commercial=1&id_partner=193"
                data={offerh}
              />
            </RowPrice>
            <RowPrice>
              <LogoSmall
                src="https://media.digitalconsultingfze.com/img/logosml/driiveme.gif"
                alt="driiveme"
              />
              <Action
                url="https://www.driiveme.com/location-utilitaire-en-aller-simple-pas-cher.html?partnerId=41"
                data={offerd}
              />
            </RowPrice>
          </PriceCont>
        </InnerRight>
      </Inner>
    </Wrapper>
  );
};

export default Hiflow;
