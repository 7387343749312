import React, { useState } from "react";
import styled from "styled-components";
import { Affix, Layout } from "antd";
import { useMediaQuery } from "react-responsive";

import { useSearchState } from "../search-context";
import { useOffersState } from "../offers-context";
import Filters from "../filters/Filters";
import Results from "../results/Results";
import MapGL from "../map/MapGL";
import Rappel from "../results/Rappel";

const { Sider } = Layout;

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR;
const tertiaryColor = process.env.REACT_APP_TERTIARY_COLOR;

const Overlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  @media (max-width: 1023px) {
    display: ${(props) => (props.active ? "none" : "block")};
  }
`;
const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
const Content = styled.div`
  position: relative;
  flex: 1;
  padding: 0 20px;
  z-index: 10;
`;
const SidebarLeftInner = styled.div`
  padding: 0 1rem;
  height: 100%;
  overflow-y: scroll;
  @media (max-width: 1200px) {
    background-color: #ffffff;
  }
`;
const SidebarRight = styled.div`
  position: relative;
  width: 600px;
  flex: none;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 640px;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 480px;
    height: 100vh;
  }
  @media (min-width: 1720px) and (max-width: 1919px) {
    width: 720px;
    height: 100vh;
  }
  @media (min-width: 1920px) {
    width: 800px;
    height: 100vh;
  }
`;
const SidebarRightInner = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    height: 100vh;
    overflow: hidden;
  }
`;
const FooterActions = styled.div`
  display: inline;
  position: fixed;
  bottom: 1rem;
  right: 0;
  z-index: 20000;
`;
const ScrollToMap = styled.button`
  border-radius: 11px;
  border: 1px solid ${secondaryColor};
  color: ${secondaryColor};
  background-color: #fefefe;
  margin-right: 1rem;
  &:disabled {
    border: 1px solid #ccc;
    color: #aaa;
    background-color: #ddd;
  }
`;
const ScrollToRappel = styled.button`
  border-radius: 11px;
  border: 1px solid ${tertiaryColor};
  color: ${tertiaryColor};
  background-color: #fefefe;
  margin-right: 1rem;
  &:disabled {
    border: 1px solid #ccc;
    color: #aaa;
    background-color: #ddd;
  }
`;
const ScrollToTop = styled.button`
  border-radius: 11px;
  border: 1px solid ${primaryColor};
  color: ${primaryColor};
  background-color: #fefefe;
  margin-right: 1rem;
  &:disabled {
    border: 1px solid #ccc;
    color: #aaa;
    background-color: #ddd;
  }
`;
const CloseWrapper = styled.div`
  padding: 1rem 0;
  display: none;
  justify-content: flex-end;
  @media (max-width: 1023px) {
    display: flex;
  }
`;
const ButtonClose = styled.button`
  border: 1px solid #dadada;
  border-radius: 6px;
`;

const SearchResults = () => {
  const mapRef = React.useRef();
  const rappelRef = React.useRef();
  const resultsRef = React.useRef();
  const { status, fromDate, proximite_km } = useSearchState();
  const { status: loading } = useOffersState();
  const [state, setState] = useState({
    key: null,
    volume: null,
    collapsed: true,
    loading: false,
    siderStyle: { position: "relative", height: "100vh" },
  });

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 1023px)",
  });

  const onMouseEnterResult = React.useCallback(
    (key, volume) => {
      setState({ ...state, key, volume });
    },
    [state]
  );

  React.useEffect(() => {
    if (loading === "data-loading") {
      setState((st) => ({ ...st, loading: true }));
      if (resultsRef.current !== undefined) {
        resultsRef.current.scrollIntoView();
      }
    } else {
      if (state.loading === true) setState((st) => ({ ...st, loading: false }));
    }
  }, [loading]);

  const closeFilters = () => {
    setState({
      ...state,
      collapsed: true,
      siderStyle: { position: "relative", height: "100vh" },
    });
  };

  const scrollToTop = React.useCallback(() => {
    closeFilters();
    resultsRef.current.scrollIntoView();
  }, []);

  const scrollToMap = React.useCallback(() => {
    closeFilters();
    mapRef.current.scrollIntoView();
  }, []);

  const scrollToRappel = React.useCallback(() => {
    closeFilters();
    rappelRef.current.scrollIntoView();
  }, []);

  if (status === "prune") return null;

  return (
    <div>
      <Layout id="dms-results">
        <Affix offsetTop={0} style={{ zIndex: 12 }}>
          <Sider
            breakpoint="xl"
            collapsedWidth="0"
            width="280"
            style={state.siderStyle}
            collapsed={state.collapsed}
            onCollapse={(collapsed, type) => {
              if (state.loading === true) {
                // Disable when loading data
                return;
              }
              const style =
                collapsed === false && type !== "responsive"
                  ? { position: "absolute", height: "100vh" }
                  : { position: "relative", height: "100vh" };
              setState({ ...state, collapsed, siderStyle: style });
            }}
          >
            <SidebarLeftInner>
              <CloseWrapper>
                <ButtonClose
                  onClick={() => {
                    closeFilters();
                  }}
                >
                  Fermer les filtres
                </ButtonClose>
              </CloseWrapper>
              <Filters fromDate={fromDate} proximite={proximite_km} />
            </SidebarLeftInner>
          </Sider>
        </Affix>
        <Layout>
          <Main className={`${state.collapsed ? "" : "hasSidebar"}`}>
            <Overlay
              active={state.collapsed}
              onClick={() => {
                closeFilters();
              }}
            />
            <Content ref={resultsRef}>
              <Results callback={onMouseEnterResult} />
            </Content>
            <SidebarRight>
              {isMobileDevice ? (
                <SidebarRightInner ref={mapRef} className="dms-carte">
                  <MapGL
                    hovered={state.key}
                    volume={state.volume}
                    parentRef={mapRef}
                  />
                  <div ref={rappelRef}>
                    <Rappel />
                  </div>
                </SidebarRightInner>
              ) : (
                <Affix offsetTop={0}>
                  <SidebarRightInner ref={mapRef} className="dms-carte">
                    <MapGL
                      hovered={state.key}
                      volume={state.volume}
                      parentRef={mapRef}
                    />
                    <div ref={rappelRef}>
                      <Rappel />
                    </div>
                  </SidebarRightInner>
                </Affix>
              )}
            </SidebarRight>
          </Main>
        </Layout>
      </Layout>

      {isMobileDevice ? (
        <FooterActions>
          <ScrollToRappel
            onClick={() => scrollToRappel()}
            disabled={state.loading}
          >
            Je déménage
          </ScrollToRappel>
          <ScrollToMap onClick={() => scrollToMap()} disabled={state.loading}>
            Carte
          </ScrollToMap>
          <ScrollToTop onClick={() => scrollToTop()} disabled={state.loading}>
            Haut
          </ScrollToTop>
        </FooterActions>
      ) : null}
    </div>
  );
};

export default SearchResults;
