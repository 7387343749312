import React from "react";

import Header from "../components/Header";
import SEO from "../components/Seo";
import { SearchProvider } from "../components/compare/search-context";
import { OffersProvider } from "../components/compare/offers-context";
import Search from "../components/compare/search/Search";
import SearchResults from "../components/compare/search/SearchResults";

export default ({ location }) => {
  return (
    <>
      <SEO
        title={`Location utilitaire ${process.env.REACT_APP_VILLE}`}
        lang="fr"
        description={`Louer un utilitaire à ${process.env.REACT_APP_VILLE} et sa région`}
        meta={[
          { name: "robots", content: "noindex" },
          { name: "robots", content: "nofollow" },
        ]}
      />
      <Header ville={process.env.REACT_APP_VILLE} />
      <SearchProvider>
        <OffersProvider>
          <Search params={location.state} />
          <SearchResults />
        </OffersProvider>
      </SearchProvider>
    </>
  );
};
