import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";

import { trackOutboundLink } from "../../utils";

const { TabPane } = Tabs;
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const Container = styled.div`
  width: 240px;
`;
const Title = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 3px 32px 3px 8px;
  background-color: #414141;
  color: #ffffff;
  font-size: 0.7rem;
  line-height: 0.8rem;
  border-radius: 6px 6px 0 0;
`;
const Offers = styled.div`
  padding: 8px;
  overflow-x: hidden;
  overflow-y: auto;
`;
const OfferLabel = styled.div`
  font-size: 0.7rem;
  line-height: 1rem;
  margin-bottom: 5px;
`;
const OfferInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
const OfferPhoto = styled.div`
  display: flex;
  width: 130px;
  height: 96px;
  overflow: hidden;
  flex: none;
  justify-content: center;
  align-items: center;
`;
const OfferDesc = styled.div`
  display: flex;
  width: 100px;
  height: 96px;
  flex: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const OfferPrice = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  margin: 0 0 5px 0;
  color: #c8462b;
`;
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${primaryColor};
  border-radius: 6px;
  height: 32px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-family: "Lato", sans-serif;
  padding: 0 10px;
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const Content = ({ volume, children }) => {
  const [key, setKey] = React.useState();

  React.useEffect(() => {
    setKey(volume);
  }, [volume]);

  return (
    <Tabs
      activeKey={key}
      size="small"
      onChange={(activeKey) => {
        setKey(activeKey);
      }}
    >
      {children}
    </Tabs>
  );
};

const Pane = ({ offer, company }) => {
  const handleClick = () => {
    trackOutboundLink(company, offer.cloacking, "pr");
  };

  return (
    <>
      <OfferLabel>{offer.name}</OfferLabel>
      <OfferInfo>
        <OfferPhoto>
          <img src={offer.img} alt={offer.name} style={{ width: "100%" }} />
        </OfferPhoto>
        <OfferDesc>
          <OfferPrice>
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(offer.price)}
          </OfferPrice>
          <Button
            href={offer.cloacking}
            className="dms-map-btn-voir"
            data-agence={company}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
          >
            Voir
          </Button>
        </OfferDesc>
      </OfferInfo>
    </>
  );
};

const PopupContent = ({ data, volume }) => {
  if (data.offers.length === 1) {
    return (
      <Container>
        <Title>
          {data.company} {data.name}
        </Title>
        <Offers>
          <Pane offer={data.offers[0]} company={data.company} />
        </Offers>
      </Container>
    );
  }

  let panes = [];
  for (let item of data.offers) {
    panes.push(
      <TabPane tab={`${item.volume}m3`} key={String(item.volume)}>
        <Pane offer={item} company={data.company} />
      </TabPane>
    );
  }

  return (
    <Container>
      <Title>
        {data.company} {data.name}
      </Title>
      <Offers>
        <Content volume={String(volume ? volume : data.offers[0].volume)}>
          {panes}
        </Content>
      </Offers>
    </Container>
  );
};

export default PopupContent;
