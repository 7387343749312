import React from "react";
import styled from "styled-components";
import { Collapse, Radio } from "antd";
import { format, differenceInDays } from "date-fns";
import fr from "date-fns/locale/fr";

import iconbplaces from "../img/ico-nb-places.svg";
import icoboitemanuelle from "../img/ico-boite-manuelle.svg";
import icoclim from "../img/ico-clim.svg";
import iconbportes from "../img/ico-nb-portes.svg";
import icoboiteauto from "../img/ico-boite-auto.svg";

import Filter from "./Filter";

import { useSearchDispatch } from "../search-context";
import { useOffersState, useOffersDispatch } from "../offers-context";

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const Wrapper = styled.div`
  .filters-collapse {
    background: transparent;
  }
  .filters-collapse .filters-collapse-panel {
    border-bottom: none;
    margin-bottom: 0.5rem;
  }
  .filters-collapse .filters-collapse-panel .ant-collapse-header {
    color: ${primaryColor};
    font-size: 1.125rem;
    font-weight: 700;
    border-bottom: 1px solid #d3d3d3;
  }
  #dms-filter-agencies .ant-checkbox-wrapper span {
    font-size: 0.8rem;
  }
  .filters-collapse .filters-collapse-panel .ant-collapse-header .anticon {
    color: #414141;
  }
  .filters-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0.5rem 0 0 0 !important;
  }
  .filters-collapse .ant-collapse-header {
    padding-left: 0 !important;
  }
`;
const Disclaimer = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  text-align: justify;
  background-color: #ffffff;
  font-size: 0.7rem;
  border-radius: 20px;
`;
const DisclaimerDanger = styled.div`
  color: ${primaryColor};
`;
const Help = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 1rem 0;
`;
const IconWrapper = styled.div`
  width: 40px;
  font-size: 0.6rem;
  line-height: 0.6rem;
  color: #515151;
  text-align: center;
`;
const Icon = styled.span`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #515151;
  color: #ffffff;
  margin: 0 auto 4px auto;
  flex: none;
  justify-content: center;
  align-items: center;
`;
const SearchDay = styled.div`
  color: ${primaryColor};
  font-size: 0.8rem;
  padding-top: 0.5rem;
`;

const { Panel } = Collapse;

// Labels dictionnaries
const categoriesNames = {
  1: "Camionnette (3m³)",
  2: "Moyen (5m³ et 12m³)",
  3: "Grand (+12m³)",
};

const leasersNames = {
  "1": "Professionnel",
  "0": "Particulier",
};

const Filters = ({ fromDate, proximite }) => {
  const offers = useOffersState();
  const setOffers = useOffersDispatch();
  const setSearch = useSearchDispatch();

  /**
   * All option change handler
   * @param {*} e
   */
  const orderOnChange = React.useCallback(
    (e) => {
      if (offers.status === "loaded")
        setOffers({
          type: "order",
          payload: e.target.value,
        });
    },
    [offers.status, setOffers]
  );

  const rangeOnChange = React.useCallback(
    (e) => {
      if (offers.status === "loaded")
        setSearch({
          type: "update-range",
          payload: e.target.value,
        });
    },
    [offers.status, setSearch]
  );

  return (
    <Wrapper>
      <SearchDay>
        Recherche pour le {format(fromDate, "EEEE dd/MM/yyyy", { locale: fr })}
      </SearchDay>
      <Collapse
        bordered={false}
        defaultActiveKey={["1", "2", "3"]}
        expandIconPosition="right"
        className="filters-collapse"
      >
        <Panel header="Trier par" key="1" className="filters-collapse-panel">
          <Radio.Group
            defaultValue={offers.filters.order}
            size="medium"
            buttonStyle="solid"
            onChange={orderOnChange}
            disabled={offers.status !== "loaded"}
          >
            <Radio.Button value="price">Prix</Radio.Button>
            <Radio.Button value="distance">Proximité</Radio.Button>
          </Radio.Group>
        </Panel>
        <Panel header="Rayon" key="2" className="filters-collapse-panel">
          <Radio.Group
            value={proximite}
            size="medium"
            buttonStyle="solid"
            onChange={rangeOnChange}
            disabled={offers.status !== "loaded"}
          >
            <Radio.Button value="0.06">5km</Radio.Button>
            <Radio.Button value="0.1">10km</Radio.Button>
            <Radio.Button value="0.15">15km</Radio.Button>
          </Radio.Group>
        </Panel>
        <Panel header="Loueurs" key="3" className="filters-collapse-panel">
          <Filter type="companies" groupKey="agence" />
        </Panel>
        <Panel header="Catégories" key="4" className="filters-collapse-panel">
          <Filter
            type="categories"
            groupKey="category"
            dict={categoriesNames}
          />
        </Panel>
        <Panel
          header="Type de location"
          key="5"
          className="filters-collapse-panel"
        >
          <Filter type="leaser" groupKey="type_location" dict={leasersNames} />
        </Panel>
        <Panel
          header="Agences"
          key="6"
          className="filters-collapse-panel"
          id="dms-filter-agencies"
        >
          <Filter type="agencies" groupKey="agid" />
        </Panel>
      </Collapse>
      <Help>
        <IconWrapper>
          <Icon style={{ backgroundColor: "#aaaaaa" }}>
            <img
              src={iconbplaces}
              alt="Nombre de places"
              style={{ height: "18px" }}
            />
          </Icon>
          Nb. places
        </IconWrapper>
        <IconWrapper>
          <Icon key="clim">
            <img src={icoclim} alt="Climatisé" style={{ height: "18px" }} />
          </Icon>
          Climatisé
        </IconWrapper>
        <IconWrapper>
          <Icon key="clim">
            <img
              src={icoboitemanuelle}
              alt="Boîte manuelle"
              style={{ height: "18px" }}
            />
          </Icon>
          Boîte manuelle
        </IconWrapper>
        <IconWrapper>
          <Icon key="clim">
            <img
              src={icoboiteauto}
              alt="Boîte automatique"
              style={{ height: "18px" }}
            />
          </Icon>
          Boîte auto
        </IconWrapper>
        <IconWrapper>
          <Icon key="clim">
            <img
              src={iconbportes}
              alt="Nombre de portes"
              style={{ height: "14px" }}
            />
          </Icon>
          Nb. portes
        </IconWrapper>
      </Help>
      <Disclaimer>
        Il reste {differenceInDays(fromDate, new Date())} jours avant le{" "}
        {format(fromDate, "dd/MM/yyyy")}. En moyenne les internautes réservent
        un utilitaire 7 jours avant la date de prise de véhicule. Notre
        conseil&nbsp;: Louez le + tôt possible avant qu’il n’y ait plus de
        véhicule disponible&nbsp;!
        <DisclaimerDanger>
          * Nos Prix sont à titre indicatif et ne tiennent pas compte des
          options et assurances de chaque partenaire.
        </DisclaimerDanger>
      </Disclaimer>
    </Wrapper>
  );
};

export default Filters;
