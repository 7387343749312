import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { Checkbox } from "antd";

import { useOffersState, useOffersDispatch } from "../offers-context";

const Row = styled.div`
  margin-bottom: 0.5rem;
`;
const Badge = styled.span`
  display: inline-block;
  border-radius: 10px;
  padding: 0 5px;
  background-color: #ffffff;
  color: #999999;
  margin-left: 3px;
  font-size: 0.7rem;
  min-width: 20px;
  text-align: center;
`;

const initialState = {
  stats: {},
  options: [],
  plainOptions: [],
  checkedList: [],
  indeterminate: true,
  checkAll: false,
};

const filterReducer = (state, action) => {
  let local = { ...state };

  switch (action.type) {
    case "update":
      for (let [key, value] of Object.entries(action.payload)) {
        local[key] = value;
      }
      break;

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return local;
};

const Filter = ({ type, groupKey, dict }) => {
  const offersState = useOffersState();
  const setOffers = useOffersDispatch();
  const [state, dispatch] = React.useReducer(filterReducer, initialState);

  React.useEffect(() => {
    // All the possible options
    let options = [];
    let plainOptions = [];

    if (type !== "agencies") {
      plainOptions = Object.keys(offersState[type]);
      plainOptions.sort();
    } else {
      offersState.filters.companies.forEach((item) => {
        if (offersState.agencies.hasOwnProperty(item)) {
          options = [...options, ...offersState.agencies[item]];
        }
      });

      plainOptions = options.map((item) => item.id);
      options = _.orderBy(options, [(item) => item.name], ["asc"]);
    }

    // The stats
    const stats = _.countBy(offersState.filtered, (item) => item[groupKey]);

    dispatch({
      type: "update",
      payload: {
        options,
        plainOptions,
        stats,
        checkedList: offersState.filters[type],
      },
    });
  }, [offersState, type, groupKey]);

  /**
   * Option change handler
   * @param {Array} checkedList
   */
  const onChange = React.useCallback(
    (checkedList) => {
      dispatch({
        type: "update",
        payload: {
          checkedList,
          indeterminate:
            !!checkedList.length &&
            checkedList.length < state.plainOptions.length,
          checkAll: checkedList.length === state.plainOptions.length,
        },
      });

      // Don't apply filter if all data haven't been loaded
      if (offersState.status === "loaded") {
        setOffers({
          type: "filter",
          payload: {
            key: type,
            value: checkedList,
          },
        });
      }
    },
    [setOffers, state.plainOptions.length, offersState.status, type]
  );

  /**
   * All option change handler
   * @param {*} e
   */
  const onCheckAllChange = React.useCallback(
    (e) => {
      const checkedList = e.target.checked ? state.plainOptions : [];
      dispatch({
        type: "update",
        payload: {
          checkedList: checkedList,
          indeterminate: false,
          checkAll: e.target.checked,
        },
      });
      if (offersState.status === "loaded") {
        setOffers({
          type: "filter",
          payload: {
            key: type,
            value: checkedList,
          },
        });
      }
    },
    [setOffers, state.plainOptions, offersState.status, type]
  );

  let checkboxes = [];

  if (type !== "agencies") {
    state.plainOptions.forEach((item) => {
      checkboxes.push(
        <Row key={`${type}-${item}`}>
          <Checkbox value={item}>
            {dict.hasOwnProperty(item) ? dict[item] : item}{" "}
            <Badge>
              {state.stats.hasOwnProperty(item) ? state.stats[item] : 0}
            </Badge>
          </Checkbox>
        </Row>
      );
    });
  } else {
    state.options.forEach((item) => {
      checkboxes.push(
        <Row key={item.id}>
          <Checkbox value={item.id}>
            {item.name}
            <Badge>
              {state.stats.hasOwnProperty(item.id) ? state.stats[item.id] : 0}
            </Badge>
          </Checkbox>
        </Row>
      );
    });
  }

  return (
    <>
      <Row>
        <Checkbox
          indeterminate={state.indeterminate}
          onChange={onCheckAllChange}
          checked={state.checkAll}
          disabled={offersState.status !== "loaded"}
        >
          Toutes
        </Checkbox>
      </Row>
      <Checkbox.Group
        value={state.checkedList}
        onChange={onChange}
        size="medium"
        disabled={offersState.status !== "loaded"}
      >
        {checkboxes}
      </Checkbox.Group>
    </>
  );
};

Filter.defaultProps = { dict: {} };

Filter.propTypes = {
  dict: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
};

export default Filter;
