import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;
const Animation = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: ${({ size }) => (size ? `${size}px` : "32px")};
  height: ${({ size }) => (size ? `${size}px` : "32px")};
  animation: ${spin} 2s linear infinite;
  display: ${({ visible }) => (visible ? "inline-block" : "none")};
  margin-right: 10px;
`;

const Loader = ({ size, visible }) => {
  return <Animation size={size} visible={visible} />;
};

export default Loader;
