import React from "react";
import styled from "styled-components";

import { trackOutboundLink } from "../../utils";

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${process.env.REACT_APP_PRIMARY_COLOR};
  border-radius: 6px;
  height: 32px;
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  width: 100%;
  max-width: 240px;
  min-width: 70px;
  &:hover {
    background-color: #000000;
    color: #ffffff !important;
  }
`;

const Action = ({ url, data, pid }) => {
  const handleClick = () => {
    trackOutboundLink(data.agence, url, pid);
  };

  if (data.agence === "HIFLOW" || data.agence === "DRIIVEME") {
    return (
      <Button
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        Voir
      </Button>
    );
  }
  return (
    <Button
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      Voir{" "}
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        data-svg="arrow-right"
      >
        <polyline
          fill="none"
          stroke="#FFF"
          points="10 5 15 9.5 10 14"
        ></polyline>
        <line fill="none" stroke="#FFF" x1="4" y1="9.5" x2="15" y2="9.5"></line>
      </svg>
    </Button>
  );
};

export default Action;
