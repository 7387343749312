import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;
const PriceWrapper = styled.div`
  margin: 0 0.5rem;
`;
const NormalPrice = styled.div``;
const RealPrice = styled.div`
  font-size: 1.25rem;
  line-height: 1.25rem;
`;
const Striked = styled.span`
  position: relative;
  display: inline-block;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;

    -webkit-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
`;
const Suffix = styled.small`
  font-size: 0.7rem;
`;

const Prefix = ({ prefix }) => {
  if (prefix === undefined) return null;

  return <>{prefix}</>;
};

const Promo = ({ price, promotion }) => {
  if (promotion === undefined || promotion >= price) return null;

  return (
    <>
      <strong>{promotion} &euro;*</strong>
      <Suffix>prix promo</Suffix>
    </>
  );
};

const Normal = ({ promotion, price, agency, suffix, hide, ad }) => {
  let formattedPrice = "";
  if (price !== undefined)
    formattedPrice = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(price);

  if (agency !== undefined && agency !== null && agency !== 0) {
    let formattedAgency = "";
    formattedAgency = new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
    }).format(agency);

    if (promotion === undefined || promotion === null) {
      if (hide === false) {
        if (ad === false) {
          return (
            <PriceWrapper>
              <NormalPrice>
                <Striked>{formattedAgency}</Striked> <Suffix>{suffix}</Suffix>
              </NormalPrice>
              <div>
                <RealPrice>{formattedPrice} *</RealPrice>
                <Suffix>en payant en ligne</Suffix>
              </div>
            </PriceWrapper>
          );
        }
        return (
          <PriceWrapper>
            <NormalPrice>
              <Striked>{formattedAgency}</Striked> <Suffix>{suffix}</Suffix>
            </NormalPrice>
            <div>
              <RealPrice>{formattedPrice}</RealPrice>
              <Suffix>en payant en ligne</Suffix>
            </div>
          </PriceWrapper>
        );
      }
    }

    return (
      <PriceWrapper>
        <strong>{formattedAgency}</strong> <Suffix>{suffix}</Suffix>
      </PriceWrapper>
    );
  }

  if (promotion === undefined || promotion === null) {
    if (hide === false) {
      if (ad === false) {
        return (
          <PriceWrapper>
            <RealPrice>{formattedPrice} *</RealPrice> <Suffix>{suffix}</Suffix>
          </PriceWrapper>
        );
      }
      return (
        <PriceWrapper>
          <RealPrice>{formattedPrice}</RealPrice> <Suffix>{suffix}</Suffix>
        </PriceWrapper>
      );
    }
  }

  return (
    <PriceWrapper>
      <RealPrice>{formattedPrice}</RealPrice> <Suffix>{suffix}</Suffix>
    </PriceWrapper>
  );
};

const Price = (props) => {
  return (
    <Wrapper>
      <Prefix prefix={props.prefix} />
      <Normal
        promotion={props.promotion}
        price={props.price}
        agency={props.agency}
        suffix={props.suffix}
        hide={props.hide}
        ad={props.ad}
      />
      <Promo promotion={props.promotion} price={props.price} />
    </Wrapper>
  );
};

export default Price;
