import React from "react";
import styled from "styled-components";
import slugify from "slugify";

import Price from "./Price";
import Action from "./Action";
import Commodities from "./Commodities";

const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR;

const Wrapper = styled.div`
  background-color: #ffffff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #d3d3d3;
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    flex-direction: column;
  }
`;
const InnerLeft = styled.div`
  flex: 1;
`;
const InnerRight = styled.div`
  width: 300px;
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    width: 100%;
  }
`;
const PhotoWrapper = styled.div`
  width: 145px;
  max-height: 103px;
  overflow: hidden;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 369px) {
    width: 125px;
  }
`;
const PriceCont = styled.div`
  display: flex;
  width: 145px;
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    width: auto;
    flex: 1;
  }
`;
const InfosTitle = styled.div`
  color: ${secondaryColor};
`;
const InfosDetails = styled.p`
  margin: 0.5rem 0;
  font-size: 0.8rem;
  color: gray;
`;
const InfosPlus = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 667px), (min-width: 1024px) and (max-width: 1200px) {
    margin-bottom: 0.5rem;
  }
`;
const InfosLogo = styled.div`
  width: 80px;
  height: ${(props) => (props.agence === "CARGO" || props.agence === "OUICAR" ? "40px" : "30px")};
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.img`
  width: ${(props) => (props.alt === "cargo" || props.alt === "ouicar" ? "40px" : "100%")};
  max-width: ${(props) => (props.alt === "CARGO" || props.alt === "OUICAR" ? "40px" : "80px")};
`;
const Photo = styled.img`
  width: 100%;
`;
const Disclaimer = styled.span`
  font-size: 0.7rem;
  font-style: italic;
`;

const Result = ({ offer, pid, callback }) => {
  const logo = `https://media.digitalconsultingfze.com/img/logosml/${slugify(
    offer.agence,
    { lower: true }
  )}.gif`;

  return (
    <Wrapper
      className="dms-result"
      onMouseEnter={() => callback(offer.agid, String(offer.volume))}
    >
      <Inner className="dms-result-inner">
        <InnerLeft className="dms-result-inner-left">
          <InfosTitle>{offer.vehicule}</InfosTitle>
          <InfosDetails>
            {offer.agence} {offer.nom_agence}{" "}
            {offer.agenceDepart &&
            offer.agenceRetour &&
            offer.agenceDepart.name !== offer.agenceRetour.name
              ? `- ${offer.agenceRetour.agence} ${offer.agenceRetour.name} `
              : ""}
            <br />
            {offer.agenceDepart.adresse
              ? `  ${offer.agenceDepart.adresse}`
              : ""}
            <br />
            {offer.km ? `${offer.km} km inclus` : ""}
          </InfosDetails>
          <InfosPlus className="dms-infoplus">
            <InfosLogo agence={offer.agence}>
              <Logo
                
                src={logo}
                alt={offer.agence}
                
              />
            </InfosLogo>
            <Commodities data={offer} />
          </InfosPlus>
        </InnerLeft>
        <InnerRight className="dms-result-inner-right">
          <PhotoWrapper>
            <Photo
              alt={offer.vehicule}
              
              src={offer.img}
              
            />
          </PhotoWrapper>
          <PriceCont>
            <Price
              promotion={offer.prix_promo}
              price={offer.prix}
              agency={offer.prix_agence}
              prefix={offer.prefix_prix}
              suffix={offer.suffix_prix}
              hide={!!offer.hideprix}
              ad={!!offer.annonce}
            />
            <Action url={offer.cloacking} data={offer} pid={pid} />
          </PriceCont>
        </InnerRight>
      </Inner>
      {offer.agence === "LOCATION U" ? (
        <Disclaimer>
          * Attention, il est possible que ce véhicule ne soit plus disponible
          ou que le prix ait changé.
        </Disclaimer>
      ) : null}
    </Wrapper>
  );
};

export default Result;
