import React, { useEffect, useReducer, useCallback } from "react";
import ReactMapGL, {
  Marker,
  Popup,
  FlyToInterpolator,
  WebMercatorViewport,
} from "react-map-gl";
import _ from "lodash";
import slugify from "slugify";
import * as d3 from "d3-ease";
import bbox from "@turf/bbox";
import * as turf from "@turf/helpers";
import "mapbox-gl/dist/mapbox-gl.css";

import { useSearchState } from "../search-context";
import { useOffersState } from "../offers-context";
import PopupContent from "./PopupContent";

/**
 * Group offers by Agency and by categories
 * Generate all datas used in the map
 * @param {*} items
 */
const _transform = (items) => {
  // Group by agency
  let grouped = _.groupBy(items, (item) => item.agid);
  let data = {};

  const agencies = Object.keys(grouped);

  agencies.forEach((agency) => {
    // Group by category
    const byCategory = _.groupBy(grouped[agency], (item) => item.volume);

    let categories = [];
    _.forIn(byCategory, (value) => {
      // Take the min offer by category
      const offer = _.minBy(value, (o) => o.prixPromo);
      categories.push({
        cloacking: offer.cloacking,
        currency: offer.devise,
        price: offer.prix > offer.prixPromo ? offer.prixPromo : offer.prix,
        price_in_agency: offer.prix_agence,
        price_label: offer.suffix_prix,
        img: offer.img,
        name: offer.vehicule,
        volume: offer.volume,
      });
    });

    data[agency] = {
      lat: parseFloat(grouped[agency][0].lat_agence),
      lng: parseFloat(grouped[agency][0].lng_agence),
      company: grouped[agency][0].agence,
      code: grouped[agency][0].agence_code,
      name: grouped[agency][0].nom_agence,
      icon: `https://media.digitalconsultingfze.com/img/gmap/${slugify(
        grouped[agency][0].agence,
        { lower: true }
      )}.png`,
      offers: categories,
    };
  });

  return data;
};

/**
 * Returns an array of geospatial points markers coordinates
 * @param {*} markers
 */
const _getBounds = (markers) => {
  const features = [];

  _.forIn(markers, (item) => {
    features.push(turf.point([item.lng, item.lat]));
  });

  return turf.featureCollection(features);
};

const Markers = ({ data, callback }) => {
  const markers = [];

  if (Object.keys(data).length === 0) return null;

  _.forIn(data, (item, key) => {
    markers.push(
      <Marker
        key={key}
        longitude={item.lng}
        latitude={item.lat}
        offsetLeft={-15}
        offsetTop={-15}
      >
        <img
          src={item.icon}
          alt={item.name}
          style={{ cursor: "pointer" }}
          onClick={() => callback(key)}
        />
      </Marker>
    );
  });

  return <>{markers}</>;
};

const mapReducer = (state, action) => {
  let local = { ...state };

  const updateViewport = (width) => {
    // Get an arrays of all markers coordinates
    const features = _getBounds(local.markers);
    // Get the min and max coordinates
    const [minLng, minLat, maxLng, maxLat] = bbox(features);
    // Get the viewport from these min max
    if (minLng !== Infinity) {
      local.viewport.width = width;
      local.viewport = new WebMercatorViewport(local.viewport);
      const bounds = local.viewport.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 20 }
      );
      //Transition to this viewport
      local.viewport = {
        ...local.viewport,
        longitude: bounds.longitude,
        latitude: bounds.latitude,
        zoom: Math.min(bounds.zoom, 17),
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubic,
      };
    }
  };

  switch (action.type) {
    case "resize":
      updateViewport(action.payload);
      break;

    case "add-markers":
      const data = _transform(action.payload.data);
      local.data = data;

      // Filter the marker to be shown
      local.markers = _.pick(
        local.data,
        _.map(action.payload.data, (item) => item.agid)
      );

      updateViewport(action.payload.width);

      break;
    case "popup":
      if (
        action.payload.key !== null &&
        local.markers.hasOwnProperty(action.payload.key)
      ) {
        const clicked = local.data[action.payload.key];
        local.showPopup = true;
        local.clicked = clicked;
        local.volume = action.payload.volume
          ? String(action.payload.volume)
          : local.data[action.payload.key][0];
        local.viewport = {
          ...local.viewport,
          longitude: clicked.lng,
          latitude: clicked.lat,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator(),
          transitionEasing: d3.easeCubic,
        };
      } else {
        local.clicked = null;
        local.volume = null;
        local.showPopup = false;
      }

      break;
    case "viewport":
      const { latitude, longitude, zoom } = action.payload;
      local.viewport = { ...local.viewport, latitude, longitude, zoom };
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return local;
};

const initialState = {
  showPopup: true,
  viewport: {
    width: "100%",
    height: 460,
    latitude: null,
    longitude: null,
    zoom: 13,
  },
  data: {},
  markers: {},
  clicked: null,
  volume: null,
};

let isScrolling;
let scroll = false;

const MapGL = (props) => {
  const mapRef = React.useRef();
  const { lat_depart, lng_depart } = useSearchState();

  initialState.viewport = {
    ...initialState.viewport,
    latitude: lat_depart,
    longitude: lng_depart,
  };

  const [state, dispatch] = useReducer(mapReducer, initialState);
  const { filtered } = useOffersState();

  const onMarkerClick = useCallback((key) => {
    dispatch({ type: "popup", payload: { key } });
  }, []);

  const handleResize = React.useCallback(() => {
    if (props.parentRef.current !== undefined) {
      dispatch({
        type: "resize",
        payload: props.parentRef.current.offsetWidth,
      });
    }
  }, [props.parentRef]);

  const _handleScroll = React.useCallback((e) => {
    scroll = true;

    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function() {
      scroll = false;
    }, 100);
  }, []);

  useEffect(() => {
    // The map hasn't been unmounted, i.e we haven't re-showed the home content
    let isCancelled = false;

    if (isCancelled === false) {
      // Add new markers
      dispatch({
        type: "add-markers",
        payload: { data: filtered, width: props.parentRef.current.offsetWidth },
      });

      if (props.hovered !== null) {
        dispatch({
          type: "popup",
          payload: { key: props.hovered, volume: props.volume },
        });
      }
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", _handleScroll, false);

    handleResize();

    return () => {
      isCancelled = true;
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", _handleScroll);
    };
  }, [filtered, props.hovered, props.volume, props.parentRef, handleResize]);

  return (
    <ReactMapGL
      {...state.viewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onViewportChange={(viewport) => {
        dispatch({ type: "viewport", payload: viewport });
      }}
      reuseMaps={true}
      mapOptions={{
        style: "mapbox://styles/mapbox/streets-v11",
      }}
      ref={mapRef}
    >
      <Markers data={state.markers} callback={onMarkerClick} />
      {state.showPopup && state.clicked !== null && scroll === false && (
        <Popup
          latitude={state.clicked.lat}
          longitude={state.clicked.lng}
          tipSize={0}
          closeButton={true}
          closeOnClick={false}
          onClose={() => dispatch({ type: "popup", payload: { key: null } })}
        >
          <PopupContent data={state.clicked} volume={state.volume} />
        </Popup>
      )}
    </ReactMapGL>
  );
};

export default MapGL;
