import React from "react";
import axios from "axios";
import _ from "lodash";

import { useSearchState } from "./search-context";

const OffersStateContext = React.createContext();
const OffersDispatchContext = React.createContext();

const queryProperties = [
  "proximite_km",
  "lat_depart",
  "lng_depart",
  "vca_depart",
  "dhm_depart",
  "date_depart",
  "heure_depart",
  "min_depart",
  "dhm_retour",
  "date_retour",
  "heure_retour",
  "min_retour",
  "lat_retour",
  "lng_retour",
  "vca_retour",
  "forfait_km",
  "administrative_area_level_1",
  "carType",
  "data_algolia_dep",
  "data_algolia_ret",
  "siteweb",
];

let sources = []; // Store axios cancel tokens sources

let initialState = {
  status: "initialized",
  filters: {
    order: "price",
    range: 5,
    companies: [],
    categories: [],
    leaser: [],
    agencies: [],
  },
  companies: {},
  categories: {},
  leaser: {},
  agencies: {},
  banners: [], // bannières
  ads: [], // mises en avant
  offers: [],
  filtered: [],
  progress: 0,
};

function offersReducer(state, action) {
  let local = { ...state };

  /**
   * Sum the number of offers by companies, categories or leaser
   * @param {Array} data
   */
  const setStats = (data) => {
    // Separate offers from ads and banners
    let offers = [];
    let ads = [];
    let banners = [];

    data.forEach((item) => {
      switch (item.annonce) {
        case 1:
          ads.push(item);
          break;
        case 2:
          banners.push(item);
          break;
        default:
          offers.push(item);
          break;
      }
    });

    // Totals for offers only
    const companies = _.countBy(offers, (item) => item.agence);
    const categories = _.countBy(offers, (item) => item.category);
    const leaser = _.countBy(offers, (item) => item.type_location);

    let proOffers = offers.filter((item) => item.type_location === 1);
    let offersByCompany = _.groupBy(proOffers, (item) => item.agence);
    let offersByAgency = {};
    for (let [key, value] of Object.entries(offersByCompany)) {
      const agencies = _.groupBy(value, (item) => item.agid);
      offersByAgency[key] = [];
      for (let [subkey, subvalue] of Object.entries(agencies)) {
        const num = subvalue.length;
        const agency = subvalue.shift();
        offersByAgency[key].push({
          id: subkey,
          name: `${key} ${agency.nom_agence}`,
          num,
        });
      }
    }

    return {
      offers,
      ads,
      banners,
      companies,
      categories,
      leaser,
      agencies: offersByAgency,
    };
  };

  /**
   * Standardize values in results from API
   * @param {Array} offers
   */
  const standardizeData = (offers) => {
    return offers.map((item) => {
      // Prefix agid with company name as the ID can be the same for different companies
      item.agid = `${item.agence}_${item.agid}`;

      // Convert the price used for sorting to float
      item.prixPromo = parseFloat(item.prixPromo);

      return item;
    });
  };

  /**
   * Filter offers by type
   * @param {Array} offers
   */
  const filterByLeaser = (offers) => {
    if (local.filters.leaser.length === 0) return offers;

    return offers.filter((item) => {
      return local.filters.leaser.includes(String(item.type_location));
    });
  };

  /**
   * Filter offers by companies
   * @param {Array} offers
   */
  const filterByCompanies = (offers) => {
    if (local.filters.companies.length === 0) return offers;

    return offers.filter((item) => {
      return local.filters.companies.includes(item.agence);
    });
  };

  /**
   * Filter offers by categories
   * @param {Array} offers
   */
  const filterByCategories = (offers) => {
    if (local.filters.categories.length === 0) return offers;

    let filtered = offers.filter((item) => {
      return (
        item.category === null ||
        local.filters.categories.includes(String(item.category))
      );
    });

    return filtered;
  };

  /**
   *
   * @param {*} offers
   */
  const filterByAgencies = (offers) => {
    if (local.filters.agencies.length === 0) return offers;

    let filtered = offers.filter((item) =>
      local.filters.agencies.includes(item.agid)
    );

    return filtered;
  };

  /**
   * Order items
   * @param {Array} offers
   */
  const orderOffers = (offers) => {
    if (local.filters.order === "price") {
      return _.orderBy(
        offers,
        [(item) => item.prixPromo, (item) => item.distancecentre],
        ["asc", "asc"]
      );
    }

    return _.orderBy(
      offers,
      [(item) => item.distancecentre, (item) => item.prixPromo],
      ["asc", "asc"]
    );
  };

  /**
   * Filter and order offers
   */
  const filterOffers = () => {
    let filtered = local.offers;

    // Apply filters
    filtered = filterByLeaser(filtered);
    filtered = filterByCompanies(filtered);
    filtered = filterByCategories(filtered);
    filtered = filterByAgencies(filtered);

    // Order offers
    filtered = orderOffers(filtered);

    return filtered;
  };

  switch (action.type) {
    case "reset":
    case "fetching": {
      // Reset loaded data
      local.status = "data-loading";
      local.offers = [];
      local.ads = [];
      local.banners = [];
      local.filtered = [];
      local.companies = {};
      local.categories = {};
      local.leaser = {};
      local.agencies = {};

      // Reset filters
      if (action.type === "reset") {
        local.filters.companies = [];
        local.filters.categories = [];
        local.filters.leaser = [];
        local.filters.agencies = [];
      }
      break;
    }

    // One query has resolved
    case "fetched": {
      if (action.payload.data.length > 0) {
        const offers = standardizeData(action.payload.data);
        const stats = setStats(offers);

        local.offers = [...local.offers, ...stats.offers];
        local.ads = [...local.ads, ...stats.ads];
        local.banners = [...local.banners, ...stats.banners];
        local.companies = { ...local.companies, ...stats.companies };
        local.categories = { ...local.categories, ...stats.categories };
        local.leaser = { ...local.leaser, ...stats.leaser };
        local.agencies = { ...local.agencies, ...stats.agencies };
        local.filtered = filterOffers();
      }

      local.progress = action.payload.progress;

      break;
    }

    // All queries have resolved, all data have been loaded
    case "loaded": {
      local.status = "loaded";
      local.progress = 0;
      break;
    }

    // One or more filters have changed
    case "filter": {
      if (local.filters.hasOwnProperty(action.payload.key)) {
        local.filters[action.payload.key] = action.payload.value;
        local.filtered = filterOffers();
      }
      break;
    }

    // Order criteria has changed
    case "order":
      local.filters.order = action.payload;
      local.filtered = orderOffers(local.filtered);
      break;

    // Reset all filters
    case "reset-filters":
      local.filters.companies = [];
      local.filters.categories = [];
      local.filters.leaser = [];
      local.filters.agencies = [];
      local.filtered = filterOffers();
      break;

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return local;
}

function OffersProvider({ children }) {
  const searchParams = useSearchState();
  const [state, dispatch] = React.useReducer(offersReducer, initialState);

  const cancelRequests = React.useCallback((sources) => {
    for (let source of sources) {
      source.cancel();
    }
  }, []);

  React.useEffect(() => {
    if (
      searchParams.status === "searching" ||
      searchParams.status === "ranging"
    ) {
      if (searchParams.status === "searching") {
        dispatch({ type: "reset" });
      } else {
        dispatch({ type: "fetching" });
      }

      let params = _.pick(searchParams, queryProperties);

      const fetchAll = async () => {
        let loueurs = [...searchParams.loueurs];
        let fetched = [];

        // Cancel all queries
        cancelRequests(sources);
        sources = [];

        // Query each company one by one
        for (let loueur of loueurs) {
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          const data = {
            ...params,
            loueurs: loueur,
            cancelToken: source.token,
            action: "smlApi",
          };
          const config = {
            method: "post",
            url: process.env.REACT_APP_API_HOST,
            headers: {
              Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
              "Content-Type": "application/json",
            },
            data: data,
          };

          sources.push(source);

          axios(config)
            .then((res) => {
              if (
                res !== undefined &&
                res.status === 200 &&
                res.data.length > 0
              ) {
                dispatch({
                  type: "fetched",
                  payload: {
                    data: res.data,
                    progress: Math.round(
                      (100 * (fetched.length + 1)) / loueurs.length
                    ),
                  },
                });
              }
            })
            .catch(() => {
              dispatch({
                type: "fetched",
                payload: {
                  data: [],
                  progress: Math.round(
                    (100 * (fetched.length + 1)) / loueurs.length
                  ),
                },
              });
            })
            .finally(() => {
              fetched.push(loueur);

              if (fetched.length === loueurs.length)
                dispatch({ type: "loaded" });
            });
        }
      };

      fetchAll();
    }

    return () => {
      cancelRequests(sources);
    };
  }, [searchParams, cancelRequests]);

  return (
    <OffersStateContext.Provider value={state}>
      <OffersDispatchContext.Provider value={dispatch}>
        {children}
      </OffersDispatchContext.Provider>
    </OffersStateContext.Provider>
  );
}

function useOffersState() {
  const context = React.useContext(OffersStateContext);
  if (context === undefined) {
    throw new Error("useOffersState must be used within an OffersProvider");
  }
  return context;
}

function useOffersDispatch() {
  const context = React.useContext(OffersDispatchContext);
  if (context === undefined) {
    throw new Error("useOffersDispatch must be used within an OffersProvider");
  }
  return context;
}

export { OffersProvider, useOffersState, useOffersDispatch };
